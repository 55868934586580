import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@common/guards/auth.guard';
import { USER_TYPES } from '@constants/authentication.constant';
import {
  ADD_INSPIRATION_URL,
  ADMIN_CONTRACTOR_LIST,
  ADMIN_CREATE_USERS_URL,
  ADMIN_LOGIN_URL,
  ADMIN_PAYMENTS_URL,
  ADMIN_PROJECTS_URL,
  CHANGE_PASSWORD_URL,
  COMPANY_HOME_URL,
  CONTACT_FORM_URL,
  CONTRACTOR_CATEGORY_SELECTOR_URL,
  CONTRACTOR_DATA_URL,
  CONTRACTOR_LOGIN_URL,
  CONTRACTOR_PROJECTS_URL,
  EDIT_INSPIRATION_URL,
  ENTER_CLIENT_PROFILE,
  HOW_IT_WORKS_URL,
  LOGIN_URL,
  LOGOUT_URL,
  PISCINERIA_FORM,
  PRE_REGISTRATION_URL,
  PROJECTS_SIMULATOR_URL,
  RATING_AND_COMMENTS,
  RECOVER_PASSWORD_URL,
  REFERRED_URL,
  REGISTER_FORM_URL,
  SIMULATION_URL,
  TERMS_URL,
  VIEW_CLIENT_DATA,
} from '@common/constants/url.constant';

const routes: Routes = [
  {
    path: LOGIN_URL,
    pathMatch: 'full',
    loadChildren: () => import('./pages/login/login.module').then(module => module.LoginPageModule)
  },
  {
    path: CONTRACTOR_LOGIN_URL,
    pathMatch: 'full',
    loadChildren: () => import('./pages/login/login.module').then(module => module.LoginPageModule)
  },
  {
    path: ADMIN_LOGIN_URL,
    pathMatch: 'full',
    loadChildren: () => import('./pages/login/login.module').then(module => module.LoginPageModule)
  },
  {
    path: '',
    loadChildren: () => import('./pages/home/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: COMPANY_HOME_URL,
    canLoad: [AuthGuard],
    loadChildren: () => import('./pages/home/company-home/company-home.module').then(m => m.CompanyHomePageModule),
    data: {
      userRoles: [USER_TYPES.contractorAdmin, USER_TYPES.contractor, USER_TYPES.admin]
    }
  },
  {
    path: CONTRACTOR_DATA_URL,
    pathMatch: 'full',
    canLoad: [AuthGuard],
    loadChildren: () => import('./pages/profile/contractor-data/contractor-data.module').then(m => m.ContractorDataPageModule),
    data: {
      userRoles: [USER_TYPES.contractorAdmin, USER_TYPES.admin]
    }
  },
  {
    path: ADD_INSPIRATION_URL,
    pathMatch: 'full',
    canLoad: [AuthGuard],
    loadChildren: () => import('./pages/inspiration-add/inspiration-add.module').then(m => m.InspirationAddPageModule),
    data: {
      userRoles: [USER_TYPES.contractorAdmin, USER_TYPES.contractor, USER_TYPES.admin]
    }
  },
  {
    path: EDIT_INSPIRATION_URL,
    canLoad: [AuthGuard],
    loadChildren: () => import('./pages/inspiration-add/inspiration-add.module').then(m => m.InspirationAddPageModule),
    data: {
      userRoles: [USER_TYPES.contractorAdmin, USER_TYPES.contractor, USER_TYPES.admin]
    }
  },
  {
    path: LOGOUT_URL,
    loadChildren: () => import('./pages/logout/logout.module').then(m => m.LogoutPageModule)
  },
  {
    path: CONTRACTOR_CATEGORY_SELECTOR_URL,
    canLoad: [AuthGuard],
    loadChildren: () => import('./pages/category-selection/category-selection.module').then(m => m.CategorySelectionPageModule),
    data: {
      userRoles: [USER_TYPES.contractor, USER_TYPES.contractorAdmin, USER_TYPES.admin]
    }
  },
  {
    path: VIEW_CLIENT_DATA,
    pathMatch: 'full',
    loadChildren: () => import('./pages/view-client-data/view-client-data.module').then( m => m.ViewClientDataPageModule)
  },
  {
    path: CONTRACTOR_PROJECTS_URL,
    canLoad: [AuthGuard],
    loadChildren: () => import('./pages/contractor-projects/contractor-projects.module').then(m => m.ContractorProjectsPageModule),
    data: {
      userRoles: [USER_TYPES.contractor, USER_TYPES.contractorAdmin]
    }
  },
  {
    path: HOW_IT_WORKS_URL,
    loadChildren: () => import('./pages/home/how-it-works/how-it-works.module').then(m => m.HowItWorksPageModule),
  },
  {
    path: ADMIN_PROJECTS_URL,
    canLoad: [AuthGuard],
    loadChildren: () => import('./pages/admin/admin-projects-list/admin-projects-list.module').then( m => m.AdminProjectsListPageModule),
    data: {
      userRoles: [USER_TYPES.admin]
    }
  },
  {
    path: ADMIN_PAYMENTS_URL,
    canLoad: [AuthGuard],
    loadChildren: () => import('./pages/admin/payments/payments.module').then( m => m.PaymentsPageModule),
    data: {
      userRoles: [USER_TYPES.admin]
    }
  },
  {
    path: CONTACT_FORM_URL,
    loadChildren: () => import('./pages/sos/sos.module').then( m => m.SosPageModule),
  },
  {
    path: ADMIN_CREATE_USERS_URL,
    canLoad: [AuthGuard],
    loadChildren: () => import('./pages/admin/create-users/create-users.module').then( m => m.CreateUsersPageModule),
    data: {
      userRoles: [USER_TYPES.admin]
    }
  },
  {
    path: RATING_AND_COMMENTS,
    loadChildren: () => import('./pages/rating-and-comments/rating-and-comments.module').then( m => m.RatingAndCommentsPageModule),
  },
  {
    path: CHANGE_PASSWORD_URL,
    canLoad: [AuthGuard],
    loadChildren: () => import('./pages/change-password/change-password.module').then( m => m.ChangePasswordPageModule),
    data: {
      userRoles: [USER_TYPES.client, USER_TYPES.contractor, USER_TYPES.contractorAdmin, USER_TYPES.admin]
    }
  },
  {
    path: RECOVER_PASSWORD_URL,
    loadChildren: () => import('./pages/recover-password/recover-password.module').then( m => m.RecoverPasswordPageModule)
  },
  {
    path: TERMS_URL,
    loadChildren: () => import('./pages/terms/terms.module').then( m => m.TermsPageModule)
  },
  {
    path: ADMIN_CONTRACTOR_LIST,
    canLoad: [AuthGuard],
    loadChildren: () => import('./pages/contractors-profile-list/contractors-profile-list.module')
      .then( m => m.ContractorsProfileListPageModule),
    data: {
      userRoles: [USER_TYPES.admin]
    }
  },
  {
    path: REGISTER_FORM_URL,
    loadChildren: () => import('./pages/register-form/register-form.module').then( m => m.RegisterFormPageModule)
  },
  {
    path: REFERRED_URL,
    loadChildren: () => import('./pages/referred/referred.module').then( m => m.ReferredPageModule)
  },
  {
    path: 'activar-cuenta/:userId',
    loadChildren: () => import('./pages/activate-account/activate-account.module').then( m => m.ActivateAccountPageModule)
  },
  {
    path: ENTER_CLIENT_PROFILE,
    canLoad: [AuthGuard],
    data: {
      userRoles: [USER_TYPES.admin]
    },
    loadChildren: () => import('./pages/impersonate-client/impersonate-client.module').then( m => m.ImpersonateClientPageModule)
  },
  {
    path: SIMULATION_URL,
    loadChildren: () => import('./modules/simulation/simulation.module').then((m) => m.SimulationModule)
  },
  {
    path: 'simulador-hipotecario',
    loadChildren: () => import('./modules/simulation/simulation.module').then((m) => m.SimulationModule)
  },
  {
    path: PRE_REGISTRATION_URL,
    loadChildren: () => import('./pages/pre-registration/pre-registration.module').then( m => m.PreRegistrationPageModule )
  },
  // TODO: check all the above routes and remove the ones that are not used
  {
    path: 'categorias', // TODO: legacy route, it should be something like 'cotizar-proyecto'
    loadChildren: () => import('./modules/project-quote-request/project-quote-request.module').then((m) => m.ProjectQuoteRequestModule)
  },
  {
    path: 'catalogo-privado/categorias', // TODO: legacy route, it should be something like 'cotizar-proyecto/catalogo-privado'
    loadChildren: () => import('./modules/project-quote-request/project-quote-request.module').then((m) => m.ProjectQuoteRequestModule)
  },
  {
    path: 'home/cliente', // TODO: legacy route, delete later
    pathMatch: 'full',
    redirectTo: 'sitio-privado',
  },
  {
    path: 'sitio-privado',
    canLoad: [AuthGuard],
    loadChildren: () => import('./modules/private-site/private-site.module').then(m => m.PrivateSiteModule),
    data: {
      userRoles: [USER_TYPES.client, USER_TYPES.contractor, USER_TYPES.contractorAdmin, USER_TYPES.admin]
    }
  },
  {
    path: 'bci',
    loadComponent: () => import('./modules/landings/pages/bci-landing/bci-landing.page').then(m => m.BciLandingPage),
  },
  {
    path: 'financiamiento-consumo',
    loadComponent: () => import('./modules/landings/pages/consumption-financing-landing/consumption-financing-landing.page').then(m => m.ConsumptionFinancingLandingPage),
  },
  {
    path: 'financiamiento-fines-generales',
    loadComponent: () => import('./modules/landings/pages/general-purpose-financing-landing/general-purpose-financing-landing.page').then(m => m.GeneralPurposeFinancingLandingPage),
  },
  {
    path: 'descuentos',
    loadComponent: () => import('./modules/landings/pages/discounts/discounts-landing.page').then(m => m.DiscountsLandingPage),
  },
  {
    path: 'socovesa-mirador-de-la-frontera',
    loadComponent: () => import('./modules/landings/pages/socovesa-mirador-de-la-frontera-landing/socovesa-mirador-de-la-frontera-landing.page').then(m => m.SocovesaMiradorDeLaFronteraLanding),
  },
  {
    path: 'socovesa-toledo',
    loadComponent: () => import('./modules/landings/pages/socovesa-toledo-landing/socovesa-toledo-landing.page').then(m => m.SocovesaToledoLandingPage),
  },
  {
    path: 'socovesa-avellanos',
    loadComponent: () => import('./modules/landings/pages/socovesa-avellanos-landing/socovesa-avellanos-landing.page').then(m => m.SocovesaAvellanosLandingPage),
  },
  {
    path: 'santander',
    loadComponent: () => import('./modules/landings/pages/santander-credit-card-landing/santander-credit-card-landing.page').then(m => m.SantanderCreditCardLandingPage),
  },
  {
    path: PISCINERIA_FORM,
    pathMatch: 'full',
    loadChildren: () => import('./pages/piscineria-form/piscineria-form.module').then( m => m.PiscineriaFormPageModule)
  },
  {
    path: PROJECTS_SIMULATOR_URL,
    loadChildren: () => import('./pages/project-simulator/project-simulator.module').then( m => m.ProjectSimulatorPageModule),
  },
  { path: '**', redirectTo: '/', pathMatch: 'full' }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
