export const TERMS_AND_CONDITIONS_MOCK = {
  success: [
    {
      version: '0.0.1',
      date: '20210720',
      url: 'https://www.homein.cl/terminos-y-condiciones/0.0.1.pdf'
    }
  ],
  failures: [
    {
      path: 'path',
      statusCode: 400,
      code: 6,
      messageError: 'El servicio ha respondido con un error.'
    }
  ]
};
