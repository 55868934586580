import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  INDICATORS_COLLECTION,
  LEGAL_BASES_COLLECTION,
  MODAL_WELCOME_LITE_CLIENT,
  SIMULATION_COLLECTION,
  SIMULATION_INFORMATION_TOAST_DOC,
  UF_INDICATOR
} from '@constants/firebase.constant';
import {
  ILegalBasis, ISimulationInformation,
  ISimulationModalData, ITermsAndConditions, ITermsLatestVersion
} from '@interfaces/firestore.interface';
import { UF } from '@interfaces/payment.interface';
import { clean } from 'rut.js';
import { FirebaseService } from './firebase.abstract';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FirebaseServiceImplemented implements FirebaseService {

  constructor(
    public firestore: AngularFirestore,
  ) { }

  public async getTermsAndConditions(): Promise<ITermsAndConditions> {
    const { version } = await this.getCollectionAndDoc('terminos-y-condiciones', 'current-version') as ITermsLatestVersion;
    return await this.getCollectionAndDoc('terminos-y-condiciones', version) as ITermsAndConditions;
  }

  public async getSimulationInformation(): Promise<ISimulationInformation> {
    return await this.getCollectionAndDoc(SIMULATION_COLLECTION, SIMULATION_INFORMATION_TOAST_DOC) as ISimulationInformation;
  }

  public async showSimulationModal(rut: string, creditId: number): Promise<ISimulationModalData> {
    return await this.firestore.collection(SIMULATION_COLLECTION).doc('simulation-modal').collection('ruts').doc(rut)
      .get().toPromise().then((res) => {
        const hasShownModalInCredits = res.data() ? res.data()['hasShownModalInCredits'] : [];
        if (!res.data() || !hasShownModalInCredits.includes(creditId)) { return { creditsId: [creditId], showModal: true }; }
        if (hasShownModalInCredits.includes(creditId)) {
          return { creditsId: hasShownModalInCredits, showModal: false };
        }
      });
  }

  public async updateSimulationModal(rut: string, creditIdList: number[]): Promise<boolean> {
    return await this.firestore.collection(SIMULATION_COLLECTION).doc('simulation-modal').collection('ruts').doc(rut).set({
      hasShownModalInCredits: creditIdList
    })
      .then(() => true)
      .catch((error) => {
        throw error;
      });
  }

  public async showLiteClientModal(rut: string): Promise<boolean> {
    return await this.firestore.collection(MODAL_WELCOME_LITE_CLIENT).doc(clean(rut)).get().toPromise().then((res) => !res.data());
  }

  public async addRutToLiteClientModal(rut: string): Promise<boolean> {
    return await this.firestore.collection(MODAL_WELCOME_LITE_CLIENT).doc(rut).set({
      rut
    })
      .then(() => true)
      .catch((error) => {
        throw error;
      });
  }

  public async getUF(): Promise<UF> {
    return await this.getCollectionAndDoc(INDICATORS_COLLECTION, UF_INDICATOR) as UF;
  }

  public async updateUF(ufValue: number, ufDate: string): Promise<boolean> {
    return await this.firestore.collection(INDICATORS_COLLECTION).doc(UF_INDICATOR).set({
      value: ufValue,
      date: ufDate
    })
      .then(() => true)
      .catch((error) => {
        throw error;
      });
  }

  public async getLegalPDFArray(): Promise<ILegalBasis[]> {
    const data = [];
    const snapshot = await this.firestore.collection(LEGAL_BASES_COLLECTION).get().pipe(take(1)).toPromise();
    snapshot.docs.forEach((doc => {
      data.push(doc.data() as ILegalBasis);
    }));
    return data;
  }

  public async getSingleLegalPDF(document: string): Promise<ILegalBasis> {
    return await this.getCollectionAndDoc(LEGAL_BASES_COLLECTION, document);
  }

  private async getCollectionAndDoc(collection: string, document: string): Promise<any> {
    return await this.firestore.collection(collection).doc(document).get().toPromise().then((res) => res.data());
  }
}
